import { kTmpUidKey } from '@/shared/app-common';
import axios from '@/shared/axios';
import { BaseResponse } from '@/types/web.interface';
import Cookies from 'js-cookie';

/**
 * @description 日期数据统计-post请求体
 * @url https://yapi.rightknights.com/project/409/interface/api/18236
 * @updateDate 2024-10-08 13:14:34
 */
export interface InfoTimesReqBody {
  /** 类型 */
  type: string;
  /** 类型id */
  type_id: number;
  begin_date?: string;
  end_date?: string;
}

/**
 * @description 日期数据统计-响应体
 * @url https://yapi.rightknights.com/project/409/interface/api/18236
 * @updateDate 2024-10-08 13:14:34
 */
export interface InfoTimesResBody {
  /** 日期 */
  date?: string;
  /** 总的变化监测次数 */
  total_monitor_num?: number;
  /** 总监测次数 */
  monitor_num?: number;
  /** 最大变化率 */
  max_change_rate?: number;
  /** 是否有标记 */
  has_mark?: boolean;
  /** 是否有备注 */
  has_remark?: boolean;
  /** 监测基础数据 */
  items?: {
    /** 记录id */
    record_id?: number;
    /** 监测时间 */
    monitor_time?: string;
    /** 变化率 */
    change_rate?: number;
    /** 是否有标记 */
    has_mark?: boolean;
    /** 是否有备注 */
    has_remark?: boolean;
    logo?: string;
  }[];
}

/**
 * @description 日期数据统计
 * @url https://yapi.rightknights.com/project/409/interface/api/18236
 */
export async function infoTimes(params: InfoTimesReqBody) {
  const res = await axios.post<BaseResponse<[InfoTimesResBody]>>(
    '/instantknow/data/info/times',
    params,
  );
  return res.data;
}

/**
 * @description 数据概览-post请求体
 * @url https://yapi.rightknights.com/project/409/interface/api/18228
 * @updateDate 2024-10-08 13:14:34
 */
export interface IDataInfoReqBody {
  /** 类型 */
  type: string;
  /** 类型id */
  type_id: number;
}

/**
 * @description 数据概览-响应体
 * @url https://yapi.rightknights.com/project/409/interface/api/18228
 * @updateDate 2024-10-08 13:14:34
 */
export interface IDataInfoResBody {
  /** 是否为样例数据 */
  is_sample?: boolean;
  /** 标题 */
  title?: string;
  /** 链接 */
  url?: string;
  /** logo */
  logo?: string;
  /** 监测中的数量 */
  valid_task_num?: number;
  /** 暂停中的数量 */
  pause_task_num?: number;
  /** 总的监测次数 */
  total_monitor_num?: number;
  /** 周监测次数 */
  week_monitor_num?: number;
  /** 总的变化次数 */
  total_change_num?: number;
  /** 周变化次数 */
  week_change_num?: number;
  /** 平均变化率 */
  avg_change_rate?: number;
  /** 周平均变化率 */
  week_avg_change_rate?: number;
}

/**
 * @description 数据概览
 * @url https://yapi.rightknights.com/project/409/interface/api/18228
 */
export async function dataInfo(params: IDataInfoReqBody) {
  const res = await axios.post<BaseResponse<IDataInfoResBody>>('/instantknow/data/info', params);
  return res.data;
}

/**
 * @description 监测结果-post请求体
 * @url https://yapi.rightknights.com/project/409/interface/api/18244
 * @updateDate 2024-10-08 13:14:34
 */
export interface IMonitorResultReqBody {
  /** 类型 */
  type: string;
  /** 类型id */
  type_id: number;
  /** 分页页码 */
  page_no?: number;
  /** 每页大小 */
  page_size?: number;
}

/**
 * @description 监测结果-响应体
 * @url https://yapi.rightknights.com/project/409/interface/api/18244
 * @updateDate 2024-10-08 13:14:34
 */
export interface MonitorResultResBody {
  /** 监测记录id */
  record_id?: number;
  /** 成员id */
  member_id?: number;
  /** 标题 */
  title?: string;
  /** 链接 */
  url?: string;
  /** 图标 */
  logo?: string;
  /** 截图 */
  screen_shot?: string;
  /** 变化数量 */
  change_num?: number;
  /** 变化率 */
  change_rate?: number;
  /** 监控时间 */
  monitor_time?: string;
  /** 是否有备注 */
  has_mark?: boolean;
  /** 备注 */
  remark?: string;
  /** AI分析 */
  ai_analyze?: {
    analyze_content: string;
    tags: string[];
  };
  /** 点赞AI分析, LIKE-点赞, UNLIKE-点踩 */
  like_ai_analyze?: string;

  extra_list?: RecordAiAnalyzeResBody[];
}

/**
 * @description 监测结果
 * @url https://yapi.rightknights.com/project/409/interface/api/18244
 */
export async function monitorResult(params: IMonitorResultReqBody) {
  const res = await axios.post<BaseResponse<[MonitorResultResBody]>>(
    '/instantknow/data/monitor/result',
    params,
  );
  return res.data;
}
/**
 * @description 监测记录-post请求体
 * @url https://yapi.rightknights.com/project/409/interface/api/18252
 * @updateDate 2024-10-08 13:14:34
 */
export interface IMonitorRecordsReqBody {
  /** 类型 */
  type: string;
  /** 类型id */
  type_id: number;
  /** 分页页码 */
  page_no?: number;
  /** 每页大小 */
  page_size?: number;
}

/**
 * @description 监测记录-响应体
 * @url https://yapi.rightknights.com/project/409/interface/api/18252
 * @updateDate 2024-10-08 13:14:34
 */
export interface MonitorRecordsResBody {
  /** 记录id */
  record_id?: number;
  /** 监测时间 */
  monitor_time?: string;
  /** 变化率 */
  change_rate?: number;
  /** 是否发送告警 */
  has_send_notify?: boolean;
}

/**
/**
 * @description 监测记录
 * @url https://yapi.rightknights.com/project/409/interface/api/18252
 */
export async function monitorRecords(params: IMonitorRecordsReqBody) {
  const res = await axios.post<BaseResponse<[MonitorRecordsResBody]>>(
    '/instantknow/data/monitor/records',
    params,
  );
  return res.data;
}
/**
 * @description 监测记录详情-响应体
 * @url https://yapi.rightknights.com/project/409/interface/api/18260
 * @updateDate 2024-10-08 13:14:35
 */

export interface IRecordResBody {
  /** 来源链接 */
  source_link?: string;
  /** 旧来源链接 */
  old_source_link?: string;
  compare_source_link?: string;
  /** AI分析 */
  ai_analyze?: {
    analyze_content?: string;
    tags?: string[];
  };
  /** 监测时间 */
  monitor_time?: string;
  /** 变化率 */
  change_rate?: number;
  /** 是否有标记 */
  has_mark?: boolean;
  /** 是否有备注 */
  remark?: string;
  logo?: string;
  extra_list?: {
    extra_id?: number;
    xpath?: string;
    old_content?: string;
    new_content?: string;
    ai_analyze?: string;
    has_mark?: boolean;
  }[];
}

/**
 * @description 监测记录详情
 * @url https://yapi.rightknights.com/project/409/interface/api/18260
 */
export async function record(recordId: number) {
  const res = await axios.get<BaseResponse<IRecordResBody>>(`/instantknow/data/record/${recordId}`);
  return res.data;
}
/**
 * @description 根据记录id获取时间统计-响应体
 * @url https://yapi.rightknights.com/project/409/interface/api/18268
 * @updateDate 2024-10-08 13:14:35
 */
export interface TimesResBody {
  /** 日期 */
  date?: string;
  /** 总的监测次数 */
  total_monitor_num?: number;
  /** 最大变化率 */
  max_change_rate?: number;
  /** 是否有标记 */
  has_mark?: boolean;
  /** 是否有备注 */
  has_remark?: boolean;
  /** 监测基础数据 */
  items?: {
    /** 记录id */
    record_id?: number;
    /** 监测时间 */
    monitor_time?: string;
    /** 变化率 */
    change_rate?: number;
    /** 是否有标记 */
    has_mark?: boolean;
    /** 是否有备注 */
    has_remark?: boolean;
  }[];
}

/**
 * @description 根据记录id获取时间统计
 * @url https://yapi.rightknights.com/project/409/interface/api/18268
 */
export async function times(recordId: number) {
  const res = await axios.get<BaseResponse<[TimesResBody]>>(
    `/instantknow/data/info/times/${recordId}`,
  );
  return res.data;
}
/**
 * @description 添加或者删除标记
 * @url https://yapi.rightknights.com/project/409/interface/api/18276
 */
export async function recordMark(record_id: number) {
  const res = await axios.post<BaseResponse<any>>(
    `/instantknow/data/record/mark?record_id=${record_id}`,
  );
  return res.data;
}
/**
 * @description 添加或者删除备注-post请求体
 * @url https://yapi.rightknights.com/project/409/interface/api/18284
 * @updateDate 2024-10-08 13:14:35
 */
export interface IRecordRemarkReqBody {
  record_id: number;
  remark?: string;
}

/**
 * @description 添加或者删除备注
 * @url https://yapi.rightknights.com/project/409/interface/api/18284
 */
export async function recordRemark(params: IRecordRemarkReqBody) {
  const res = await axios.post<BaseResponse<any>>('/instantknow/data/record/remark', params);
  return res.data;
}
/**
 * @description ai分析点赞操作-query请求参数
 * @url https://yapi.rightknights.com/project/409/interface/api/18380
 * @updateDate 2024-10-08 13:14:35
 */
export interface IAilikeReqQuery {
  /** 记录id */
  record_id: number;
  operate: string;
}

/**
 * @description ai分析点赞操作
 * @url https://yapi.rightknights.com/project/409/interface/api/18380
 */
export async function ailikeOperate(params: IAilikeReqQuery) {
  const res = await axios.post<BaseResponse<any>>(
    `/instantknow/data/record/ailike/${params.operate}?record_id=${params.record_id}`,
  );
  return res.data;
}
/**
 * @description 模板列表-响应体
 * @url https://yapi.rightknights.com/project/409/interface/api/18404
 * @updateDate 2024-10-08 12:01:25
 */
export interface TemplateAllResBody {
  /** 模板名称 */
  name: string;
  /** 模板id */
  id: number;
}

/**
 * @description 模板列表
 * @url https://yapi.rightknights.com/project/409/interface/api/18404
 */
export async function templateAll() {
  const res = await axios.get<BaseResponse<TemplateAllResBody>>('/template/all');
  return res.data;
}
/**
 * @description 获取网站所有的链接-响应体
 * @url https://yapi.rightknights.com/project/409/interface/api/18292
 * @updateDate 2024-09-29 11:14:03
 */
export interface IWebsiteLinksResBody {
  /** 网页链接 */
  url?: string;
  /** 网页标题 */
  title?: string;
  /** 网页logo */
  logo?: string;
  /** 网页子链接 */
  links?: {
    /** 网页链接 */
    url?: string;
    /** 网页标题 */
    title?: string;
    /** 网页logo */
    logo?: string;
  }[];
}

/**
 * @description 获取网站所有的链接
 * @url https://yapi.rightknights.com/project/409/interface/api/18292
 */
export async function websiteLinks(url: string) {
  const res = await axios.get<BaseResponse<IWebsiteLinksResBody>>('/instantknow/website/links', {
    params: {
      url,
    },
  });
  return res.data;
}
/**
 * @description 添加主链接-post请求体
 * @url https://yapi.rightknights.com/project/409/interface/api/18300
 * @updateDate 2024-09-29 11:14:03
 */
export interface IWebsiteAddReqBody {
  /** 网页链接 */
  url: string;
  /** 网页标题 */
  title: string;
  /** 网页logo */
  logo: string;
  /** 网页子链接 */
  links?: {
    /** 网页链接 */
    url: string;
    /** 网页标题 */
    title: string;
    /** 网页logo */
    logo: string;
  }[];
}

/**
 * @description 添加主链接
 * @url https://yapi.rightknights.com/project/409/interface/api/18300
 */
export async function websiteAdd(params: IWebsiteAddReqBody) {
  const res = await axios.post<BaseResponse<any>>('/instantknow/website/add', params);
  return res.data;
}
/**
 * @description 侧边栏-列表-响应体
 * @url https://yapi.rightknights.com/project/409/interface/api/18308
 * @updateDate 2024-09-29 11:14:04
 */
export interface WebsiteListResBody {
  /** 是否为样例数据 */
  is_sample?: boolean;
  /** 父任务id */
  instant_id?: number;
  /** 链接 */
  url?: string;
  /** 标题 */
  title?: string;
  /** logo */
  logo?: string;
  /** 封面图片 */
  cover_image?: string;
  /** 分组 */
  groups?: {
    /** 分组id */
    group_id?: number;
    /** 分组名称 */
    group_name?: string;
    /** 通知数量 */
    notify_num?: number;
    /** 任务状态 */
    status?: 'NORMAL' | 'PAUSE';
    /** 成员 */
    members?: {
      /** 子链接id */
      member_id?: number;
      /** 链接 */
      url?: string;
      /** 标题 */
      title?: string;
      /** logo */
      logo?: string;
      /** 任务状态 */
      status?: 'NORMAL' | 'PAUSE';
      /** 通知数量 */
      notify_num?: number;
    }[];
  }[];
  /** 通知数量 */
  notify_num?: number;
  /** 任务状态 */
  status?: 'NORMAL' | 'PAUSE';
}

/**
 * @description 侧边栏-列表
 * @url https://yapi.rightknights.com/project/409/interface/api/18308
 */
export async function websiteList() {
  const res = await axios.get<BaseResponse<[WebsiteListResBody]>>('/instantknow/website/list');
  return res.data;
}

/**
 * @description 消息已读
 * @url https://yapi.rightknights.com/project/409/interface/api/18316
 */
export async function read(instantId: number) {
  const res = await axios.post<BaseResponse<any>>(`/instantknow/read/${instantId}`);
  return res.data;
}
/**
 * @description 分组-重命名-query请求参数
 * @url https://yapi.rightknights.com/project/409/interface/api/18324
 * @updateDate 2024-09-29 11:14:04
 */
export interface IRenameReqQuery {
  /** 分组id */
  type: string;
  /** 类型id */
  type_id: number;
  /** 新名称 */
  name: string;
}

/**
 * @description 分组-重命名
 * @url https://yapi.rightknights.com/project/409/interface/api/18324
 */
export async function rename(params: IRenameReqQuery) {
  const res = await axios.post<BaseResponse<any>>(`/instantknow/rename`, params);
  return res.data;
}
/**
 * @description 分组-任务移动-query请求参数
 * @url https://yapi.rightknights.com/project/409/interface/api/18332
 * @updateDate 2024-09-29 11:14:04
 */
export interface IGroupChangeReqQuery {
  /** 分组id */
  new_group_id: string;
  /** 新名称 */
  member_id: number;
}

/**
 * @description 分组-任务移动
 * @url https://yapi.rightknights.com/project/409/interface/api/18332
 */
export async function groupChange(params: IGroupChangeReqQuery) {
  const res = await axios.post<BaseResponse<any>>(
    `/instantknow/group/change?new_group_id=${params.new_group_id}&member_id=${params.member_id}`,
  );
  return res.data;
}
/**
 * @description 任务-删除-post请求体
 * @url https://yapi.rightknights.com/project/409/interface/api/18340
 * @updateDate 2024-09-29 11:14:05
 */
export interface IInstantknowDeleteReqBody {
  /** 类型 */
  type: string;
  /** 类型id */
  type_id: number;
}

/**
 * @description 任务-删除
 * @url https://yapi.rightknights.com/project/409/interface/api/18340
 */
export async function instantknowDelete(params: IInstantknowDeleteReqBody) {
  const res = await axios.post<BaseResponse<any>>(`/instantknow/delete`, params);
  return res.data;
}
/**
 * @description 任务-状态变更-post请求体
 * @url https://yapi.rightknights.com/project/409/interface/api/18348
 * @updateDate 2024-09-29 11:14:05
 */
export interface IStatusChangeReqBody {
  /** 类型 */
  type: string;
  /** 类型id */
  type_id: number;
  /** 状态 */
  status: string;
}

/**
 * @description 任务-状态变更
 * @url https://yapi.rightknights.com/project/409/interface/api/18348
 */
export async function statusChange(params: IStatusChangeReqBody) {
  const res = await axios.post<BaseResponse<any>>(`/instantknow/status/change`, params);
  return res.data;
}
/**
 * @description 添加子链接-post请求体
 * @url https://yapi.rightknights.com/project/409/interface/api/18356
 * @updateDate 2024-09-29 11:14:04
 */
export interface IChildAddReqBody {
  /** 主链接id */
  instant_id: number;
  /** 分组id */
  group_id: number;
  /** 监测频率，分钟数 */
  frequency: number;
  /** 提醒邮箱 */
  notify_email?: string;
  /** 提醒阈值 */
  remind_threshold?: number;
  /** 标题 */
  title: string;
  /** 链接 */
  url: string;
  /** logo */
  logo?: string;
  /** 监测范围 */
  page_range?: {
    /** 横坐标 */
    x?: number;
    /** 纵坐标 */
    y?: number;
    /** 长 */
    height?: number;
    /** 宽 */
    width?: number;
  };
}

/**
 * @description 添加子链接
 * @url https://yapi.rightknights.com/project/409/interface/api/18356
 */
export async function childAdd(params: IChildAddReqBody) {
  const res = await axios.post<BaseResponse<any>>(`/instantknow/website/child/add`, params);
  return res.data;
}

/**
 * @description 临时用户数据合并到登录用户
 * @url https://yapi.rightknights.com/project/409/interface/api/18364
 */
export async function userCombine() {
  const storedUuid = Cookies.get(kTmpUidKey);

  const res = await axios.post<BaseResponse<any>>(
    `/instantknow/user/combine`,
    {
      'tmp-uid': storedUuid,
    },
    {
      headers: {
        'tmp-uid': storedUuid,
        'Current-Language': `application/x-www-form-urlencoded`,
      },
    },
  );
  if (res?.data?.code === 0) {
    Cookies.remove(kTmpUidKey);
  }
  return res.data;
}
/**
 * @description 分组-添加-query请求参数
 * @url https://yapi.rightknights.com/project/409/interface/api/18372
 * @updateDate 2024-09-29 11:14:04
 */
export interface IGroupAddReqQuery {
  /** 主任务id */
  instant_id: string;
  /** 名称 */
  name: string;
}

/**
 * @description 分组-添加
 * @url https://yapi.rightknights.com/project/409/interface/api/18372
 */
export async function groupAdd(params: IGroupAddReqQuery) {
  const res = await axios.post<BaseResponse<any>>(
    `/instantknow/group/add?instant_id=${params.instant_id}&name=${params.name}`,
  );
  return res.data;
}
/**
 * @description 用户订阅信息-响应体
 * @url https://yapi.rightknights.com/project/409/interface/api/18388
 * @updateDate 2024-09-30 17:16:05
 */
export interface IUserInfoResBody {
  /** 订阅套餐 */
  sub_item?: 'FREE_TRY' | 'MONTH_SUBSCRIBE' | 'SEASON_SUBSCRIBE' | 'YEAR_SUBSCRIBE';
  /** 下次支付时间 */
  next_payment_date?: string;
  /** 积分余额 */
  credit?: number;
  /** 积分过期时间 */
  credit_expired_at?: string;
  /** 订阅状态 */
  status?: 'normal' | 'expired' | 'cancel';
}

/**
 * @description 用户订阅信息
 * @url https://yapi.rightknights.com/project/409/interface/api/18388
 */
export async function userSubInfo() {
  const res = await axios.get<BaseResponse<IUserInfoResBody>>('/instantknow/user/info');
  return res.data;
}
/**
 * @description 用户订阅和升级-post请求体
 * @url https://yapi.rightknights.com/project/409/interface/api/18396
 * @updateDate 2024-09-30 17:16:05
 */
export interface IUserSubReqBody {
  sub_item: string;
  redirect_url?: string;
}

/**
 * @description 用户订阅和升级-响应体
 * @url https://yapi.rightknights.com/project/409/interface/api/18396
 * @updateDate 2024-09-30 17:16:05
 */
export interface IUserSubResBody {
  order_id?: string;
  client_secret?: string;
  is_resume?: boolean;
  is_upgrade?: boolean;
}

/**
 * @description 用户订阅和升级
 * @url https://yapi.rightknights.com/project/409/interface/api/18396
 */
export async function userSub(params: IUserSubReqBody) {
  const res = await axios.post<BaseResponse<IUserSubResBody>>('/instantknow/user/sub', params);
  return res.data;
}

// 用户取消订阅
export async function userCancelSub(cancel_reason?: string) {
  const res = await axios.post<BaseResponse<any>>(
    '/instantknow/user/sub/cancel',
    { cancel_reason },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// 用户恢复订阅
export async function userResumeSub() {
  const res = await axios.post<BaseResponse<any>>('/instantknow/user/sub/resume', {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return res.data;
}
/**
 * @description 任务-设置信息-post请求体
 * @url https://yapi.rightknights.com/project/409/interface/api/18508
 * @updateDate 2024-10-15 17:40:25
 */
export interface ISettingInfoReqBody {
  /** 类型 */
  type: string;
  /** 类型id */
  type_id: number;
}

/**
 * @description 任务-设置信息-响应体
 * @url https://yapi.rightknights.com/project/409/interface/api/18508
 * @updateDate 2024-10-15 17:40:25
 */
export interface ISettingInfoResBody {
  is_global?: boolean;
  group_global?: boolean;
  title?: string;
  notify_email?: string;
  url?: string;
  /** 监测频率 */
  frequency?: number;
  /** 提醒阈值 */
  remind_threshold?: number;
  logo?: string;
  notify_cycle?: 'EVERYTIME' | 'DAILY' | 'WEEKLY';
  notify_day?: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
  cycle_time?: string;
  page_range?: {
    x?: number;
    y?: number;
    height?: number;
    width?: number;
  };
}

/**
 * @description 任务-设置信息
 * @url https://yapi.rightknights.com/project/409/interface/api/18508
 */
export async function settingInfo(params: ISettingInfoReqBody) {
  const res = await axios.post<BaseResponse<ISettingInfoResBody>>(
    '/instantknow/setting/info',
    params,
  );
  return res.data;
}
/**
 * @description 任务-设置更新-post请求体
 * @url https://yapi.rightknights.com/project/409/interface/api/18516
 * @updateDate 2024-10-15 17:40:25
 */
export interface ISettingUpdateReqBody {
  /** 类型 */
  type: string;
  /** 类型id */
  type_id: number;
  /** 全局 */
  global?: boolean;
  /** 标题 */
  title?: string;
  /** 提醒邮箱 */
  notify_email?: string;
  /** 监测频率 */
  frequency?: number;
  /** 提醒阈值 */
  remind_threshold?: number;
  notify_cycle?: 'EVERYTIME' | 'DAILY' | 'WEEKLY';
  notify_day?: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
  cycle_time?: string;
  page_range?: {
    /** 横坐标 */
    x?: number;
    /** 纵坐标 */
    y?: number;
    /** 长 */
    height?: number;
    /** 宽 */
    width?: number;
  };
}

/**
 * @description 任务-设置更新
 * @url https://yapi.rightknights.com/project/409/interface/api/18516
 */
export async function settingUpdate(params: ISettingUpdateReqBody) {
  const res = await axios.post<BaseResponse<any>>('/instantknow/setting/update', params);
  return res.data;
}

interface MonitoringFrequency {
  label: string; // 时间间隔的名称
  value: number; // 时间对应的分钟数
  locked?: string[]; // 是否被锁定，默认为 false
}

// 将数据转换为符合 TimeInterval 类型的数组
export const monitoringFrequencyList: MonitoringFrequency[] = [
  { label: 'Every 2 min', value: 2, locked: ['YEAR_SUBSCRIBE'] },
  {
    label: 'Every 5 min',
    value: 5,
    locked: ['SEASON_SUBSCRIBE', 'YEAR_SUBSCRIBE'],
  },
  {
    label: 'Every 15 min',
    value: 15,
    locked: ['MONTH_SUBSCRIBE', 'SEASON_SUBSCRIBE', 'YEAR_SUBSCRIBE'],
  },
  { label: 'Every 30 min', value: 30 },
  { label: 'Every hour', value: 60 },
  { label: 'Every 3 hour', value: 180 },
  { label: 'Every 6 hour', value: 360 },
  { label: 'Every 12 hour', value: 720 },
  { label: 'Every Day', value: 1440 },
  { label: 'Every Week', value: 10080 },
  { label: 'Every 2 Weeks', value: 20160 },
  { label: 'Every Month', value: 43200 },
];
interface Threshold {
  label: string; // 时间间隔的名称
  value: number; // 时间对应的分钟数
}

export const thresholdList: Threshold[] = [
  { label: 'Any change', value: 0 }, // 对应 "Any change"
  { label: 'Tiny(1%)', value: 1 }, // Tiny 对应 1%
  { label: 'Medium(10%)', value: 10 }, // Medium 对应 10%
  { label: 'Major(25%)', value: 25 }, // Major 对应 25%
  { label: 'Gigantic(50%)', value: 50 }, // Gigantic 对应 50%
];

export interface RecordAiAnalyzeReqQuery {
  record_id: number;
  xpath: string;
  old_content?: string;
  new_content?: string;
}

export interface RecordAiAnalyzeResBody {
  extra_id: number;
  xpath: string;
  old_content?: string;
  new_content?: string;
  ai_analyze?: string;
  has_mark?: boolean;
}

export async function recordAIAnalyze(params: RecordAiAnalyzeReqQuery) {
  const res = await axios.post<BaseResponse<RecordAiAnalyzeResBody>>(
    `/instantknow/data/record/ai_analyze`,
    params,
  );
  return res.data;
}

export async function detailAIAnalyze(params: { record_id?: any; extra_id?: any }) {
  const res = await axios.post<BaseResponse<RecordAiAnalyzeResBody>>(
    `/instantknow/data/record/ai_analyze/mark?record_id=${params?.record_id}&extra_id=${params?.extra_id}`,
  );
  return res.data;
}
