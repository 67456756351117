import type { SizeInfo } from 'rc-resize-observer';
import { createContext, useContext, useEffect, useState } from 'react';
// import useBusinessSettingsDisplay from '@/lib/hooks/common/useBusinessSettingsDisplay';
import useScreenSize from '@/lib/hooks/utils/useScreenSize';
import { useRouter } from 'next/router';
import i18nextConfig from '../../next-i18next.config';

type ProviderType = {
  isMobile?: boolean;
  isShopPage?: boolean;
  menus?: Array<any>;
  screenSize?: SizeInfo;
  locale?: string;
  handleLocale?: (loc: string) => void;
  handleToken?: (token?: string) => void;
  isIPhone?: boolean;
  onShowArticleBottomAlert?: (show?: boolean) => void;
  isShowArticleBottomAlert?: boolean;
  refreshMenu?: string;
  setRefreshMenu?: (refreshMenu: string) => void;
  refreshRecord?: string;
  setRefreshRecord?: (refreshRecord: string) => void;
};

const Context = createContext<ProviderType>({});

const Provider = ({ children }: any) => {
  const { width } = useScreenSize();
  const [screenSize, setScreenSize] = useState<SizeInfo>();
  const [locale, setLocale] = useState<string>();
  const router = useRouter();

  const [isIPhone, setIsIPhone] = useState<boolean>(undefined);
  const [isShowArticleBottomAlert, setShowArticleBottomAlert] = useState<boolean>(false);
  const [refreshMenu, setRefreshMenu] = useState<string>('');
  const [refreshRecord, setRefreshRecord] = useState<string>('');
  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsIPhone(/iPhone/i.test(userAgent));
  }, []);

  const handleLocale = (loc: string | undefined) => {
    setLocale(loc ?? i18nextConfig.i18n.defaultLocale);
  };

  const handleToken = (token?: string) => {
    // console.log(`token = ${token}`);
  };

  const onShowArticleBottomAlert = (show?: boolean) => {
    setShowArticleBottomAlert(() => show);
  };

  const exposed = {
    // menus: [],
    isShopPage: router.pathname.startsWith('/shop'),
    isMobile: width < 900,
    screenSize,
    locale,
    handleLocale,
    handleToken,
    isIPhone,
    onShowArticleBottomAlert,
    isShowArticleBottomAlert,
    refreshMenu,
    setRefreshMenu,
    refreshRecord,
    setRefreshRecord,
  };

  return (
    <Context.Provider value={exposed}>
      {/* <ResizeObserver key="resize-observer" onResize={handleIsMobile}> */}
      {children}
      {/* </ResizeObserver> */}
    </Context.Provider>
  );
};

export const useConfigProvider = () => useContext(Context);

export default Provider;
