import React from 'react';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/lib/locale';

const withTheme = (node: JSX.Element, loc: Locale) => (
  <ConfigProvider
    locale={loc}
    prefixCls="next-antd"
    iconPrefixCls="next-antd-icon"
    theme={{
      token: {
        colorPrimary: `#1E5EFF`,
        borderRadius: 6,
        fontFamily: 'Montserrat',
        fontSize: 16,
        colorLink: '#1E5EFF',
      },
      components: {},
    }}
  >
    <ConfigProvider>{node}</ConfigProvider>
  </ConfigProvider>
);

export default withTheme;
